import shadeBlendConvertColor from './ShadeWebColor';
import PortalProperties from '../types/app/PortalProperties';

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
function brightnessByColor(color: string): number | undefined {
  if (color === undefined || color === null || color.length < 1) {
    return undefined;
  }
  const isHEX = color.startsWith('#');
  const isRGB = color.startsWith('rgb');

  let r: number | undefined;
  let g: number | undefined;
  let b: number | undefined;

  if (isHEX) {
    const hasFullSpec = color.length === 7;
    const matchHex = color.substring(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (matchHex) {
      r = parseInt(matchHex[0] + (hasFullSpec ? '' : matchHex[0]), 16);
      g = parseInt(matchHex[1] + (hasFullSpec ? '' : matchHex[1]), 16);
      b = parseInt(matchHex[2] + (hasFullSpec ? '' : matchHex[2]), 16);
    }
  }
  if (isRGB) {
    const matchRGB = color.match(/(\d+){3}/g);
    if (matchRGB) {
      r = parseInt(matchRGB[0], 10);
      g = parseInt(matchRGB[1], 10);
      b = parseInt(matchRGB[2], 10);
    }
  }

  if (r === undefined || g === undefined || b === undefined) {
    return undefined;
  }

  return (r * 299 + g * 587 + b * 114) / 1000;
}

/**
 *  For tinting, the brightness in percent to the maximum brightness is matched with the gradations.
 *  2 as a minimum
 * @param brightness
 */
function getFactorTint(brightness: number | undefined): number {
  if (brightness === undefined) {
    return 15;
  }
  const factor = ((brightness / 255) * 100) / 5;

  return factor < 2 ? 2 : factor;
}

/**
 * For the shades, we take the equivalent value. (20 is the maximum.)
 * -10 as minimum
 * @param brightness
 */
function getFactorShade(brightness: number | undefined): number {
  if (brightness === undefined) {
    return -15;
  }
  const factor = ((brightness / 255) * 100) / 5 - 20;
  return factor > -10 ? -10 : factor;
}

export default function setColors(properties: PortalProperties, dE: HTMLElement | null = null) {
  const setColor = (name: string, color: string) => {
    if (dE) dE.style.setProperty(name, color);
    else document.documentElement.style.setProperty(name, color);
  };

  const primaryColorBrightness = brightnessByColor(properties.colorPrimary);
  const factorPrimaryColorTint = getFactorTint(primaryColorBrightness);
  const factorPrimaryColorShade = getFactorShade(primaryColorBrightness);

  const SecondaryColorBrightness = brightnessByColor(properties.colorSecondary);
  const factorSecondaryColorTint = getFactorTint(SecondaryColorBrightness);
  const factorSecondaryColorShade = getFactorShade(SecondaryColorBrightness);

  setColor('--menu-text', properties.colorMenuText);
  setColor(
    '--menu-text-100',
    shadeBlendConvertColor((factorPrimaryColorTint * 6) / 100, properties.colorMenuText)
  );

  setColor('--primary-button-text', properties.colorPrimaryButtonText);

  setColor(
    '--exo-palette-blue-10',
    shadeBlendConvertColor((factorPrimaryColorTint * 4) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-20',
    shadeBlendConvertColor((factorPrimaryColorTint * 3) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-30',
    shadeBlendConvertColor((factorPrimaryColorTint * 2) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-40',
    shadeBlendConvertColor((factorPrimaryColorTint * 1) / 100, properties.colorPrimary)
  );
  setColor('--exo-palette-blue-50', properties.colorPrimary);
  setColor(
    '--exo-palette-blue-60',
    shadeBlendConvertColor((factorPrimaryColorShade * 1) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-70',
    shadeBlendConvertColor((factorPrimaryColorShade * 2) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-80',
    shadeBlendConvertColor((factorPrimaryColorShade * 3) / 100, properties.colorPrimary)
  );
  setColor(
    '--exo-palette-blue-90',
    shadeBlendConvertColor((factorPrimaryColorShade * 4) / 100, properties.colorPrimary)
  );

  setColor(
    '--exo-palette-navy-10',
    shadeBlendConvertColor((factorSecondaryColorTint * 4) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-20',
    shadeBlendConvertColor((factorSecondaryColorTint * 3) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-30',
    shadeBlendConvertColor((factorSecondaryColorTint * 2) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-40',
    shadeBlendConvertColor((factorSecondaryColorTint * 1) / 100, properties.colorSecondary)
  );
  setColor('--exo-palette-navy-50', properties.colorSecondary);
  setColor(
    '--exo-palette-navy-60',
    shadeBlendConvertColor((factorSecondaryColorShade * 1) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-70',
    shadeBlendConvertColor((factorSecondaryColorShade * 2) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-80',
    shadeBlendConvertColor((factorSecondaryColorShade * 3) / 100, properties.colorSecondary)
  );
  setColor(
    '--exo-palette-navy-90',
    shadeBlendConvertColor((factorSecondaryColorShade * 4) / 100, properties.colorSecondary)
  );
}
