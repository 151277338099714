import CustomApplicationFields from '../../types/configuration/CustomApplicationFields';
import customApplicationFieldsStore from '../../stores/CustomApplicationFieldsStore';
import PaginationService from '../PaginationService';

class CustomApplicationFieldsService extends PaginationService<CustomApplicationFields> {
  constructor() {
    super('/configuration/customApplicationFields', 'Custom Fields');
  }

  storeFill(entitys: CustomApplicationFields[]): void {
    this.getStore().customApplicationFields = entitys;
  }

  storeGetAllEntitys(): CustomApplicationFields[] {
    return this.getStore().customApplicationFields;
  }

  getStore(): any {
    return customApplicationFieldsStore();
  }
}

export default new CustomApplicationFieldsService();
