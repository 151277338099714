import {
  ExtraParamPageable,
  Pageable,
  SimplePage,
  TableHeaderPageable,
} from '@apiida/controlplane-api-helper';
import apiProductStore from '../../stores/apiProductStore';
import mainStore from '../../stores/MainStore';
import PaginationService from '../PaginationService';
import ApiClient from '../ApiClient';
import Subscription from '../../types/applications/Subscription';
import ApiProduct from '../../types/apiProducts/ApiProduct';
import Api from '../../types/apis/Api';
import Plan from '../../types/configuration/Plan';
import TermsOfUse from '../../types/termsOfUse/TermOfUse';

class ApiProductService extends PaginationService<ApiProduct> {
  constructor() {
    super('/apiProducts', 'Api Product');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async getSubscriptions(
    apiProductId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<Subscription>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${apiProductId}/subscriptions`
    ) as unknown as SimplePage<Subscription>;
  }

  async getApiProductsVisible<T = ApiProduct[]>(silent: boolean = false): Promise<T[]> {
    const response = await ApiClient.get<T[]>(`${this.url}/visible`, silent);
    return response?.data ?? [];
  }

  async getApisInApiProduct(
    apiProductId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<Api>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${apiProductId}/apis`
    ) as unknown as SimplePage<Api>;
  }

  async getApiProductForApis<T = Api>(apiId: number, silent: boolean = false): Promise<T[]> {
    const response = await ApiClient.get<SimplePage<T>>(`${this.url}/visible?api=${apiId}`, silent);
    return response?.data?.content ?? [];
  }

  async getAllVisibleTagsForCurrentUser(): Promise<string[]> {
    const response = await ApiClient.get<string[]>(`/tags/visibleApiProducts`);

    return response?.data ?? [];
  }

  async getPlans(
    apiProductId: number,
    pageable: Pageable = {
      page: 1,
      size: 99999,
      sort: [],
    } as Pageable,
    header: Array<TableHeaderPageable> = [],
    extraParams: Array<ExtraParamPageable> = [],
    silent = false
  ): Promise<SimplePage<Plan>> {
    return this.getAllPagination(
      pageable,
      header,
      extraParams,
      silent,
      `${this.url}/${apiProductId}/plans`
    ) as unknown as SimplePage<Plan>;
  }

  async getTermsOfUse(apiProductId: number, active: boolean = true): Promise<TermsOfUse[]> {
    const response = await ApiClient.get<TermsOfUse[]>(
      `${this.url}/${apiProductId}/termsOfUse?active=${active}`
    );
    return response?.data ?? [];
  }

  storeFill(entities: ApiProduct[]): void {
    this.getStore().apiProducts = entities;
  }

  storeGetAllEntitys(): ApiProduct[] {
    return this.getStore().apiProducts;
  }

  getStore(): any {
    return apiProductStore();
  }
}

export default new ApiProductService();
