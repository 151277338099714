<template>
  <div>
    <div v-if="showJobViewer">
      <JobViewer :lvl1-bar-open="lvl1BarOpen" />
    </div>
    <div v-if="isGlobalAdmin">
      <div class="flex justify-start items-center rounded" data-test-id="EditMode-div">
        <BaseToggle
          id="EditMode"
          v-model="store.cmsGlobalEditMode"
          class-label="block text-md font-medium text-menu-text"
          class="pb-1"
          :label="lvl1BarOpen ? 'Edit Mode' : ''"
          @click="closeEditMode()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { BaseToggle } from '@apiida/vue-components';
import JobViewer from './JobViewer.vue';
import RoleGuards from '../../routers/guards/RoleGuards';
import mainStore from '../../stores/MainStore';

export default defineComponent({
  components: { JobViewer, BaseToggle },
  props: {
    showJobViewer: {
      type: Boolean,
      required: true,
    },
    lvl1BarOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const isGlobalAdmin = ref(false);
    const store = mainStore();

    async function loadRole() {
      isGlobalAdmin.value = RoleGuards.isGlobalAdmin();
    }

    onMounted(async () => {
      await loadRole();
    });

    onUpdated(async () => {
      await loadRole();
    });

    function closeEditMode() {
      if (store.cmsGlobalTinymceMode) {
        store.cmsGlobalEditMode = true;
        store.cmsGlobalSaveDialog = true;
      }
    }

    return { closeEditMode, store, isGlobalAdmin };
  },
});
</script>
