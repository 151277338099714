import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ApiRouter from './ApiRouter';
import AuthService from '../services/app/AuthService';
import SettingsRouter from './SettingsRouter';
import AuthRouter from './AuthRouter';
import ApplicationsRouter from './ApplicationsRouter';
import ApiProductsRouter from './ApiProductRouter';
import UsersRouter from './UsersRouter';
import NotifyCenter from '../services/NotifyCenter';
import userStore from '../stores/UserStore';
import mainStore from '../stores/MainStore';
import isAllowedPath from '../helper/RouterHelper';

const routes = [
  {
    path: '/',
    redirect: () => 'welcome',
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: () => import('../views/WelcomeView.vue'),
  },
  ...ApiRouter,
  ...SettingsRouter,
  ...AuthRouter,
  ...ApplicationsRouter,
  ...ApiProductsRouter,
  ...UsersRouter,
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: () => import('../views/NotFound.vue'),
  },
] as RouteRecordRaw[];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith('/samlResponse')) {
    await AuthService.samlLogin(to.params.samlOTToken as string);
    userStore().isLoginSaml = true;
    next('/welcome');
  } else {
    userStore().isLoginSaml = false;
  }

  let isAuthenticated = AuthService.isThereAToken();
  // load User object if not present in mainStore
  if (isAuthenticated && mainStore().loggedInUser?.id === undefined) {
    // If the loading of the current user fails, something is wrong with the token
    isAuthenticated = await AuthService.loadcurrentUser(false);
  }

  if (isAuthenticated || isAllowedPath(to.path)) {
    // user is allowed to enter this path
    next();
  } else {
    if (to.redirectedFrom?.path !== '/') {
      NotifyCenter.loginRequired();
    }
    next('/auth');
  }

  if (isAuthenticated && to.path === '/auth') {
    // The user calls /auth directly. So we let him log in again.
    localStorage.removeItem('accessToken');
    next('/');
  }
});

router.onError((error) => {
  console.error('router.onError', error);

  const isDeployError =
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed');

  if (isDeployError) window.location.reload();
});

export default router;
