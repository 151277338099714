import ApiClient from '../ApiClient';
import DocPage from '../../types/documentation/DocPage';
import { DocEntityType } from '../../types/enums/DocEntityType';

class DocPageService {
  async getAll(
    entityId: number,
    entityType: DocEntityType = DocEntityType.API
  ): Promise<DocPage[]> {
    let results: DocPage[] = [];
    const response = await ApiClient.get<DocPage[]>(`/${entityType}/${entityId}/pages`);

    if (response !== null) {
      results = response.data;
    }

    return results;
  }

  async get(
    entityId: number,
    pageId: number,
    entityType: DocEntityType = DocEntityType.API
  ): Promise<DocPage | null> {
    let result: DocPage | null = null;

    const response = await ApiClient.get<DocPage>(`/${entityType}/${entityId}/pages/${pageId}`);

    if (response !== null) {
      result = response.data;
    }

    return result;
  }

  async getContent(
    entityId: number,
    pageId: number,
    entityType: DocEntityType = DocEntityType.API
  ): Promise<string> {
    return (
      (await ApiClient.get<string>(`/${entityType}/${entityId}/pages/${pageId}/content`))?.data ??
      ''
    );
  }

  async getApiWelcomePage(id: number): Promise<{
    rawContent: string;
    docPage: DocPage | null;
  }> {
    const response = await ApiClient.get<DocPage>(`/apis/${id}/pages/default`);
    if (response === null || response.data.id === undefined) {
      return { rawContent: '', docPage: null };
    }
    const rawContent = await this.getContent(id, response.data.id);
    return { rawContent, docPage: response.data };
  }

  async getRootPage(
    id: number,
    entityType: DocEntityType = DocEntityType.API
  ): Promise<DocPage[] | null> {
    let result: DocPage[] | null = null;
    const response = await ApiClient.get<DocPage[]>(`/${entityType}/${id}/pages/root`);
    if (response !== null) {
      result = response.data;
    }

    return result;
  }

  async getApiProductWelcomePage(id: number): Promise<{
    rawContent: string;
    docPage: DocPage | null;
  }> {
    const response = await ApiClient.get<DocPage>(`/apiProducts/${id}/pages/default`);
    if (response === null || response.data.id === undefined) {
      return { rawContent: '', docPage: null };
    }

    const rawContent = await this.getContent(id, response.data.id, DocEntityType.API_PRODUCT);
    return { rawContent, docPage: response.data };
  }
}

export default new DocPageService();
