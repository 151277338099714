<template>
  <BaseButtonDialog
    id="createAccount"
    ref="baseButtonDialog"
    title="Create Account"
    :inner-dialog-button-caption="'Create Account'"
    inner-dialog-button-icon="PlusCircleIcon"
    :open-dialog-button-classes="openDialogButtonClasses"
    :open-dialog-button-caption="openDialogButtonCaption"
    :open-dialog-button-icon="openDialogButtonIcon"
    @confirm-dialog="createAccount"
    ><p class="text-sm text-gray-500 mb-2">
      To get access to {{ getEntityTxt() }} you need an account. You can create one here or click on
      the login button if you already have one. If you want to join an existing organisation ask the
      administrator of this organisation to invite you
    </p>
    <base-input id="name" v-model="user.displayName" label="Name" />
    <base-input id="email" v-model="user.email" type="email" label="Email" />

    <template #moreButtons>
      <BaseButton id="login" icon="ArrowRightOnRectangleIcon" @click="toLogin">Login</BaseButton>
      <BaseButton id="cancel" @click="$refs.baseButtonDialog.closeDialog()">Cancel</BaseButton>
    </template>
  </BaseButtonDialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { BaseButton, BaseButtonDialog, BaseInput } from '@apiida/vue-components';
import { User } from '@apiida/controlplane-api-helper';
import { useRoute, useRouter } from 'vue-router';
import UserService from '../../services/users/UserService';
import NotifyCenter from '../../services/NotifyCenter';

export default defineComponent({
  components: { BaseButton, BaseInput, BaseButtonDialog },
  props: {
    openDialogButtonClasses: {
      type: String,
      default: '',
    },
    openDialogButtonCaption: {
      type: String,
      default: 'Create Account',
    },
    openDialogButtonIcon: {
      type: String,
      default: '',
    },
  },
  setup() {
    const user = ref<User>({} as User);
    const router = useRouter();
    const route = useRoute();

    function toLogin() {
      router.push('/auth');
      window.location.assign('/auth');
    }

    function getEntityTxt(): string {
      const pathArray = route.path.split('/');
      if (pathArray.includes('apis')) {
        if (pathArray.length === 2) return 'these APIs';
        return 'this API';
      }

      if (pathArray.includes('apiProducts')) {
        if (pathArray.length === 2) return 'these API products';
        return 'this API product';
      }
      return 'this';
    }

    async function createAccount() {
      const result = await UserService.createNewAccount(user.value.email, user.value.displayName);
      if (result) {
        NotifyCenter.success(
          'Account created!',
          'Please check your inbox to confirm your email address.'
        );
      }
      user.value = {} as User;
    }

    return { user, toLogin, createAccount, getEntityTxt };
  },
});
</script>
