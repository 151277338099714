<template>
  <nav class="sidebar flex-1" aria-label="Sidebar" style="margin-top: -16px">
    <div v-if="items" class="space-y-1">
      <!-- parents -->

      <div v-for="parent in items" :key="parent.name">
        <DocPageSidebarItem
          :doc-page="parent"
          :entity-type="entityType"
          @toggle-expand="toggleExpand(parent)"
        />

        <!-- childs -->
        <div v-if="parent.expanded && parent.children && parent.children.length > 0">
          <div v-for="child in parent.children" :key="child.name">
            <DocPageSidebarItem
              :doc-page="child"
              item-class="ml-6"
              :entity-type="entityType"
              @toggle-expand="toggleExpand(child)"
            />

            <!-- grandchilds -->
            <div v-if="child.expanded && child.children.length > 0">
              <div v-for="grandchild in child.children" :key="grandchild.name">
                <DocPageSidebarItem
                  :doc-page="grandchild"
                  item-class="ml-12"
                  :entity-type="entityType"
                  @toggle-expand="toggleExpand(child)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from 'vue';

import DocPage from '../../types/documentation/DocPage';
import DocPageSidebarItem from './DocPageSidebarItem.vue';
import DocPageService from '../../services/documentation/DocPageService';
import { DocEntityType } from '../../types/enums/DocEntityType';

export default defineComponent({
  components: { DocPageSidebarItem },
  props: {
    entityId: {
      type: Number,
      required: true,
    },
    entityType: {
      type: String as PropType<DocEntityType>,
      default: DocEntityType.API,
    },
  },
  setup(props) {
    const items = ref<DocPage[]>();

    async function loadData() {
      items.value = await DocPageService.getAll(props.entityId, props.entityType);
    }

    onMounted(async () => {
      await loadData();
    });

    function toggleExpand(docPage: DocPage) {
      docPage.expanded = !docPage.expanded;
    }

    return {
      items,
      toggleExpand,
    };
  },
});
</script>
