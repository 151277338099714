<template>
  <PageHeader
    :title="title"
    :subtitle="subtitle"
    :show-search="showSearch"
    @update-filter="updateFilter"
  >
    <template #Breadcrumbs>
      <BreadcrumbList />
    </template>
    <template #underSearchBar>
      <slot name="underSearchBar" />
    </template>

    <template #buttons>
      <slot name="buttons" />
      <create-account-button
        v-if="!RoleGuards.isLoggedInUser()"
        :open-dialog-button-caption="getBtnText(route)"
      />
    </template>
  </PageHeader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PageHeader } from '@apiida/vue-components';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';
import BreadcrumbList from './BreadcrumbList.vue';
import CreateAccountButton from './CreateAccountButton.vue';
import RoleGuards from '../../routers/guards/RoleGuards';

export default defineComponent({
  components: {
    CreateAccountButton,
    BreadcrumbList,
    PageHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-filter'],
  setup(_, { emit }) {
    const route = useRoute();

    function getBtnText(userRoute: RouteLocationNormalizedLoaded): string {
      let routePath = '/';
      if (userRoute) routePath = userRoute.path;

      const pathArray = routePath.split('/');
      if (pathArray.includes('apiProducts')) {
        if (pathArray.length === 2) return 'Get access to these API products';
        return 'Get access to this API product';
      }
      if (pathArray.includes('apis')) {
        if (pathArray.length === 2) return 'Get access to these APIs';
        return 'Get access to this API';
      }
      return 'Get access';
    }

    function updateFilter(searchText: string) {
      emit('update-filter', searchText);
    }

    return { updateFilter, RoleGuards, route, getBtnText };
  },
});
</script>
