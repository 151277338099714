import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/settings',
    name: 'AccountSettings',
    meta: { BreadcrumbName: 'Account Settings' },
    component: () => import('../views/settings/AccountSettings.vue'),
    beforeEnter: RoleGuards.isLoggedInUser,
  },
] as RouteRecordRaw[];
