<template>
  <router-link
    :to="{
      name: `${getEntityRouterName()}`,
      params: { docPageId: docPage.id, entityType: entityType },
    }"
    :class="[
      itemClass,
      'sidebar-link',
      'group flex items-center px-2 py-2 text-sm font-normal rounded-md text-gray-900',
      'hover:font-semibold hover:bg-primary-100 hover:text-primary-button-text',
    ]"
    @click="toggleExpand"
  >
    <span class="flex-none">
      <span v-if="docPage.type === DocPageType.FOLDER">
        <FolderOpenIcon v-if="docPage.expanded" class="mr-3 h-6 w-6" />
        <FolderIcon v-else class="mr-3 h-6 w-6" />
      </span>
      <img
        v-else-if="docPage.type === DocPageType.MARKDOWN"
        alt="Markdown"
        class="mr-3 h-5 w-6"
        :src="Markdown_mark"
      />
      <img v-else alt="HTML" class="mr-3 h-5 w-6" :src="html_icon_grey" />
    </span>

    <div class="grow line-clamp-1">
      {{ docPage.name }}
    </div>

    <span v-if="docPage.children && docPage.children.length > 0" class="flex-none w-3">
      <ArrowDownCircleIcon v-if="docPage.expanded" class="mr-3 h-4 w-4" />
      <ArrowUpCircleIcon v-else class="mr-3 h-4 w-4" />
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  FolderIcon,
  FolderOpenIcon,
} from '@heroicons/vue/24/outline';
import DocPage from '../../types/documentation/DocPage';
import { DocEntityType } from '../../types/enums/DocEntityType';
import Markdown_mark from '../../assets/Markdown-mark-grey.svg?inline';
import html_icon_grey from '../../assets/html-icon-grey.svg?inline';
import { DocPageType } from '../../types/documentation/DocPageType';

export default defineComponent({
  components: { FolderIcon, FolderOpenIcon, ArrowUpCircleIcon, ArrowDownCircleIcon },
  props: {
    docPage: {
      type: Object as PropType<DocPage>,
      required: true,
    },
    itemClass: {
      type: String,
      default: '',
    },
    entityType: {
      type: String as PropType<DocEntityType>,
      default: DocEntityType.API,
    },
  },
  emits: ['toggle-expand'],
  setup(props, { emit }) {
    function getEntityRouterName() {
      if (props.entityType === DocEntityType.API) return 'ApiDocumentation';
      return 'ApiProductDocumentation';
    }

    function toggleExpand() {
      if (props.docPage.children && props.docPage.children.length > 0) {
        emit('toggle-expand');
      }
    }

    return { Markdown_mark, html_icon_grey, toggleExpand, getEntityRouterName };
  },
  computed: {
    DocPageType() {
      return DocPageType;
    },
  },
});
</script>
