import { defineStore } from 'pinia';
import {
  DateFormat,
  GeneralConfiguration,
  TimeFormat,
  User,
} from '@apiida/controlplane-api-helper';
import SaaSInfo from '../types/app/SaaSInfo';
import PortalProperties from '../types/app/PortalProperties';

const mainStore = defineStore({
  id: 'main',
  state: () => ({
    loggedInUser: {} as User,
    token: '',
    cmsGlobalEditMode: false,
    cmsGlobalSaveDialog: false,
    cmsGlobalTinymceMode: false,
    error: '',
    config: {} as GeneralConfiguration,
    SaaSInfo: {} as SaaSInfo,
    firstLoginFlag: false,
    showLoginScreen: false,
    customizations: {} as PortalProperties,
  }),
  actions: {
    setFirstLoginFlag(flag: boolean) {
      this.firstLoginFlag = flag;
    },
    setCustomizations(portalProperties: PortalProperties) {
      this.customizations = portalProperties;
    },
  },
  getters: {
    getCustomizations(): PortalProperties {
      return this.customizations;
    },
    getFirstLoginFlag(): boolean {
      return this.firstLoginFlag;
    },
    getLoggedInUser(): User {
      if (this.loggedInUser == null) {
        throw Error('logged in user not found');
      }
      return this.loggedInUser;
    },
    getToken(): string {
      return this.token;
    },
    getError(): string {
      return this.error;
    },
    getConfig(): GeneralConfiguration {
      if (this.config == null || this.config.dateFormat === undefined) {
        throw Error('Configuration not found');
      }
      return this.config;
    },
    getDateFormat(): string {
      // https://day.js.org/docs/en/display/format
      try {
        switch (this.getConfig.dateFormat) {
          case DateFormat.us: {
            return 'MM/DD/YYYY';
          }
          case DateFormat.uswrittenout: {
            return 'MMMM D, YYYY';
          }
          case DateFormat.german: {
            return 'DD.MM.YYYY';
          }
          default: {
            // DateFormat.international
            return 'YYYY-MM-DD';
          }
        }
      } catch (error) {
        console.log('config not yet loaded, return default.');
        return 'YYYY-MM-DD';
      }
    },
    getDayMonthFormat(): string {
      try {
        switch (this.getConfig.dateFormat) {
          case DateFormat.us: {
            return 'MM/DD';
          }
          case DateFormat.uswrittenout: {
            return 'MMMM D';
          }
          case DateFormat.german: {
            return 'DD.MM';
          }
          default: {
            // DateFormat.international
            return 'MM-DD';
          }
        }
      } catch (error) {
        console.log('config not yet loaded, return default.');
        return 'YYYY-MM-DD';
      }
    },
    getTimeFormat(): string {
      try {
        const format = this.getConfig.timeFormat;
        if (format === TimeFormat.us) {
          return 'h:mm:ss A';
        }
      } catch (error) {
        console.log('config not yet loaded, return default.');
      }
      return 'HH:mm:ss';
    },
  },
});
export default mainStore;
