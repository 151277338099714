<template>
  <MultiColumnLayout
    v-if="api.id !== undefined"
    :hide-bar-by-small-resolution="hideBarBySmallResolution"
  >
    <template #sidebar>
      <div class="flex flex-col items-center">
        <ImageAvatar
          :src="image"
          :alt="api.displayName + 'image'"
          :default-avatar="getRandomRocketByID(api.id)"
          img-size="h-44 w-44"
        >
        </ImageAvatar>

        <p class="pt-5 w-full truncate text-center text-base font-semibold text-gray-900">
          {{ api.displayName }}
        </p>
      </div>

      <ApiSidebar :api-id="apiId" />
    </template>

    <slot />
  </MultiColumnLayout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { getRandomRocketByID, ImageAvatar } from '@apiida/vue-components';
import ApiService from '../../services/apis/ApiService';
import ApiSidebar from './ApiSidebar.vue';
import Api from '../../types/apis/Api';

export default defineComponent({
  components: {
    ImageAvatar,
    ApiSidebar,
  },
  props: {
    apiId: {
      type: Number,
      required: true,
    },
    propImage: {
      type: String,
      default: '',
    },
    hideBarBySmallResolution: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const api = ref<Api>({} as Api);
    const image = ref('');

    async function loadImage(forceReload: boolean = false) {
      await ApiService.getImage(props.apiId, forceReload, (base64Image: string) => {
        image.value = base64Image;
      });
    }

    onMounted(async () => {
      api.value = await ApiService.get(props.apiId);
      await loadImage();
    });

    watch(
      () => props.propImage,
      (newValue) => {
        image.value = newValue;
      }
    );

    return { getRandomRocketByID, api, image, loadImage };
  },
});
</script>
