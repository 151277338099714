import { OrganizationType, User, UserRole } from '@apiida/controlplane-api-helper';
import mainStore from '../../stores/MainStore';
import UserService from '../../services/users/UserService';

function getCurrentUser(): User {
  let user = mainStore().loggedInUser;

  // If the user is not defined, we simply load him.
  if (user.role === undefined) {
    user = { role: [] } as unknown as User;
  }
  return user;
}

export default {
  isLoggedInUser(): boolean {
    return (
      !(localStorage.getItem('accessToken') ?? '').trim().includes('anon_') &&
      (localStorage.getItem('accessToken') ?? '').trim().length > 0
    );
  },
  isAnonLoggedIn(): boolean {
    return (localStorage.getItem('accessToken') ?? '').trim().includes('anon_');
  },
  isUser(): boolean {
    return getCurrentUser().role === UserRole.user;
  },
  isAdmin(): boolean {
    return getCurrentUser().role === UserRole.admin;
  },
  isGlobalAdmin(): boolean {
    const tmpUser = getCurrentUser();
    if (tmpUser.role) {
      return tmpUser.role === UserRole.globaladmin;
    }
    return false;
  },
  isAdminOrGlobalAdmin(): boolean {
    const user = getCurrentUser();
    return user.role === UserRole.globaladmin || user.role === UserRole.admin;
  },

  async isLoginUserPublishingOrg(): Promise<boolean> {
    const user = getCurrentUser();
    const userOrganization = await UserService.getOrganizationById(user.organization);
    if (userOrganization.type === OrganizationType.PUBLISHER) {
      return true;
    }
    return false;
  },
};
