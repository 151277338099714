import './index.css';
import '@boomi/exosphere/dist/styles.css';
import '@boomi/exosphere';
import { createApp } from 'vue';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue';
import PacmanLoader from 'vue-spinner/src/PacmanLoader.vue';
import Notifications from 'notiwind';
import { createPinia } from 'pinia';
import {
  BaseButton,
  BaseCard,
  BaseCell,
  BaseContainer,
  BaseDialog,
  BaseInput,
  BaseTable,
  ButtonNewEntityDialog,
  DeleteConfirmButton,
  ListInput,
  MultiColumnLayout,
  PageLayout,
  PrimaryButton,
  SaveButton,
  SingleColumnLayout,
} from '@apiida/vue-components';
import BeatLoader from 'vue-spinner/src/BeatLoader.vue';
import CustomizedRafikiDynamic from './components/app/CustomizedRafikiDynamic.vue';
import App from './App.vue';
import router from './routers/Router';
import ObsidianPageHeader from './components/app/ObsidianPageHeader.vue';
import config from './config';
import { insertTenantId } from './helper/TenantHelper';

// Others
const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
// @ts-ignore
app.use(Notifications);

// Application wide components
// base

// as a plugin
// or as a component

// @ts-ignore
document.getElementById('favIcon48').href = `${insertTenantId(config.favIconLink)}`;
// @ts-ignore
document.getElementById('favIconAny').href = `${insertTenantId(config.favIconLink)}`;
// @ts-ignore
document.getElementById('favIconAppleTouchIcon').href = `${insertTenantId(config.favIconLink)}`;

app.component('CustomizedRafikiDynamic', CustomizedRafikiDynamic);
app.component('PrimaryButton', PrimaryButton);
app.component('SaveButton', SaveButton);
app.component('BaseButton', BaseButton);
app.component('BaseCard', BaseCard);
app.component('BaseContainer', BaseContainer);
app.component('BaseInput', BaseInput);
app.component('ListInput', ListInput);
app.component('BaseDialog', BaseDialog);
app.component('BaseTable', BaseTable);
app.component('BaseCell', BaseCell);
app.component('BounceLoader', BounceLoader);
app.component('PacmanLoader', PacmanLoader);
app.component('MultiColumnLayout', MultiColumnLayout);
app.component('SingleColumnLayout', SingleColumnLayout);
app.component('PageLayout', PageLayout);
app.component('ObsidianPageHeader', ObsidianPageHeader);
// extended
app.component('ButtonNewEntityDialog', ButtonNewEntityDialog);
app.component('DeleteConfirmButton', DeleteConfirmButton);
app.component('BeatLoader', BeatLoader);

app.mount('#app');
