import { defineStore } from 'pinia';
import ApiProduct from '../types/apiProducts/ApiProduct';

const apiProductStore = defineStore({
  id: 'apiProduct',
  state: () => ({
    apiProducts: [] as ApiProduct[],
    images: [] as { apiProductId: number; image: string }[],
  }),
  getters: {
    getApiProducts(): ApiProduct[] {
      return this.apiProducts;
    },
  },
  actions: {
    getApiProductByDI(apiProductId: number): ApiProduct | undefined {
      return this.apiProducts.find(
        (appInArray: ApiProduct) => Number(appInArray.id) === Number(apiProductId)
      );
    },
    getImage(apiProductId: number): string | undefined {
      const apiProductImage: { apiProductId: number; image: string } | undefined = this.images.find(
        (aI: { apiProductId: number; image: string }) =>
          Number(aI.apiProductId) === Number(apiProductId)
      );
      if (apiProductImage) {
        return apiProductImage.image;
      }
      return undefined;
    },
    storeOrUpdateImage(apiProductId: number, image: string): void {
      let storeIt = true;
      this.images.map((aI: { apiProductId: number; image: string }) => {
        if (Number(aI.apiProductId) === Number(apiProductId)) {
          aI.image = image;
          storeIt = false;
        }
        return aI;
      });
      if (storeIt) {
        this.images.push({ apiProductId, image });
      }
    },
    deleteImage(apiProductId: number): void {
      const indexOfObject = this.images.findIndex(
        (aI: { apiProductId: number; image: string }) =>
          Number(aI.apiProductId) === Number(apiProductId)
      );
      this.images.splice(indexOfObject, 1);
    },
  },
});

export default apiProductStore;
