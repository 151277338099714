import { RouteRecordRaw } from 'vue-router';
import RoleGuards from './guards/RoleGuards';

export default [
  {
    path: '/apis',
    name: 'API',
    component: () => import('../views/EmptyPage.vue'),
    meta: { isEntity: true, BreadcrumbName: 'APIs' },
    props: (route) => ({
      ...route.params,
      ...{ apiId: Number(route.params.apiId) },
    }),
    children: [
      {
        path: '',
        name: 'APIs',
        component: () => import('../views/apis/ApiList.vue'),
      },
      {
        path: ':apiId',
        name: 'API',
        component: () => import('../views/apis/ApiRouterView.vue'),
        children: [
          {
            path: '',
            name: 'ApiOverview',
            component: () => import('../views/apis/ApiOverview.vue'),
          },
          {
            path: 'TryItOut',
            name: 'ApiTryItOut',
            component: () => import('../views/apis/ApiTryItOut.vue'),
          },
          {
            path: 'documentation/:docPageId/:entityType',
            name: 'ApiDocumentation',
            component: () => import('../views/documentation/DocPageView.vue'),
            props: (route) => ({
              ...route.params,
              ...{ docPageId: Number(route.params.docPageId) },
            }),
          },
          {
            path: 'versions',
            name: 'ApiVersionsBase',
            component: () => import('../views/EmptyPage.vue'),
            props: true,
            beforeEnter: RoleGuards.isLoggedInUser,
            children: [
              {
                path: '',
                name: 'ApiVersions',
                component: () => import('../views/apis/ApiVersions.vue'),
              },
              {
                path: ':versionName',
                name: 'VersionDetails',
                component: () => import('../views/apis/versions/VersionDetails.vue'),
              },
            ] as RouteRecordRaw[],
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
