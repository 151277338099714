import {
  ApiClientHelper,
  BasicUserService,
  Organization,
  User,
} from '@apiida/controlplane-api-helper';
import apiClient from '../ApiClient';
import userStore from '../../stores/UserStore';

class UserService extends BasicUserService {
  constructor() {
    super(apiClient);
  }

  async getOrganizationById(orgId: number): Promise<Organization> {
    const response = await apiClient.get<Organization>(`/organizations/${orgId}`);
    return response?.data ?? ({} as Organization);
  }

  storeFill(entitys: User[]): void {
    this.getStore().users = entitys;
  }

  storeGetAllEntitys(): User[] {
    return this.getStore().users;
  }

  getStore(): any {
    return userStore();
  }

  async createNewAccount(email: string, displayName: string): Promise<boolean> {
    const response = await apiClient.post(`/selfService/userAndOrganization`, {
      email,
      displayName,
    });
    return ApiClientHelper.isSuccess(response);
  }

  async getInvitationUser(token: string): Promise<User> {
    const response = await apiClient.get<User>(`/users/invitation?token=${token}`);
    return response?.data ?? ({} as User);
  }
}

export default new UserService();
