import environmentConfig from './environmentConfig';

const config = () => ({
  version: environmentConfig.version,
  backendUrl: environmentConfig.backendUrl,
  backendWs: environmentConfig.backendWs,
  supportUrl: environmentConfig.supportUrl,
  devPortalToken: environmentConfig.devPortalToken,
  insertTenantId: environmentConfig.insertTenantId,

  tinyEditorApiKey: environmentConfig.tinyEditorApiKey, // entwickler@apiida - Do not change this

  allowDarkMode: false,
  allowTutorial: true,
  favIconLink: `${environmentConfig.backendUrl}/devPortals/current/favicon?DevPortalToken=${environmentConfig.devPortalToken}`,

  defaultCMS: {
    home: {
      page: 'home',
      defaultBlock: 'home_content',
    },
    apiList: {
      page: 'api-list',
    },
    apiOverview: {
      page: 'api-overview',
    },
    apiDocumentation: {
      page: 'api-documentation',
    },
    apiVersionList: {
      page: 'api-version-list',
    },
    apiSwaggerUi: {
      page: 'api-swagger-ui',
    },
    applicationList: {
      page: 'application-list',
    },
    applicationOverview: {
      page: 'application-overview',
    },
    applicationSubscriptions: {
      page: 'application-subscriptions',
    },
    applicationCustomFields: {
      page: 'application-custom-fields',
    },
    apiProductList: {
      page: 'api-product-list',
    },
    apiProductOverview: {
      page: 'api-product-overview',
    },
    apiProductApis: {
      page: 'api-product-apis',
    },
    apiProductPlans: {
      page: 'api-product-plans',
    },
    apiProductDocumentation: {
      page: 'api-product-documentation',
    },
  },
});

export default config();
