import { RouteRecordRaw } from 'vue-router';

export default [
  {
    path: '/apiProducts',
    name: 'ApiProduct',
    component: () => import('../views/EmptyPage.vue'),
    meta: { isEntity: true, BreadcrumbName: 'API Products' },
    props: (route) => ({
      ...route.params,
      ...{ apiProductId: Number(route.params.apiProductId) },
    }),
    children: [
      {
        path: '',
        name: 'ApiProducts',
        component: () => import('../views/apiProducts/ApiProductList.vue'),
      },
      {
        path: ':apiProductId',
        name: 'ApiProduct',
        component: () => import('../views/apiProducts/ApiProductRouterView.vue'),
        children: [
          {
            path: '',
            name: 'ApiProductOverview',
            component: () => import('../views/apiProducts/ApiProductOverview.vue'),
          },
          {
            path: 'apis',
            name: 'ApiProductAPIs',
            component: () => import('../views/apiProducts/ApiProductAPIs.vue'),
          },
          {
            path: 'plans',
            name: 'ApiProductPlans',
            component: () => import('../views/apiProducts/ApiProductPlans.vue'),
          },
          {
            path: 'documentation/:docPageId/:entityType',
            name: 'ApiProductDocumentation',
            component: () => import('../views/documentation/DocPageView.vue'),
            props: (route) => ({
              ...route.params,
              ...{ docPageId: Number(route.params.docPageId) },
            }),
          },
        ] as RouteRecordRaw[],
      },
    ] as RouteRecordRaw[],
  },
] as RouteRecordRaw[];
