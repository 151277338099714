import Api from '../../types/apis/Api';
import apiStore from '../../stores/apiStore';
import mainStore from '../../stores/MainStore';
import Plan from '../../types/configuration/Plan';
import ApiClient from '../ApiClient';
import PaginationService from '../PaginationService';
import Subscription from '../../types/applications/Subscription';

class ApiService extends PaginationService<Api> {
  constructor() {
    super('/apis', 'API');
  }

  getMainStore() {
    // @ts-ignore
    return mainStore(this.$pinia);
  }

  async getPlans(apiId: number): Promise<Plan[]> {
    const response = await ApiClient.get<Plan[]>(`${this.url}/${apiId}/plans`);
    return response?.data ?? [];
  }

  async getSubscriptions<T = Subscription>(apiId: number, silent: boolean = false): Promise<T[]> {
    const response = await ApiClient.get<T[]>(`/apis/${apiId}/subscriptions`, silent);
    return response?.data ?? [];
  }

  async getAllVisibleTagsForCurrentUser(): Promise<string[]> {
    const response = await ApiClient.get<string[]>(`/tags/visibleApis`);

    return response?.data ?? [];
  }

  storeFill(entitys: Api[]): void {
    this.getStore().apis = entitys;
  }

  storeGetAllEntitys(): Api[] {
    return this.getStore().apis;
  }

  getStore(): any {
    return apiStore();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(_data: Api, _silent?: boolean): Promise<Api | null> {
    throw new Error('not Allowed');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(_data: Api | undefined, _silent?: boolean): Promise<boolean> {
    throw new Error('not Allowed');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(_id: number, _silent?: boolean): Promise<boolean> {
    throw new Error('not Allowed');
  }
}

export default new ApiService();
